.search {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
   

    &__icon {
      height: 3.4vh;
      padding: 0.5vw;
      position: absolute;
      cursor: pointer;
      fill: #fff;
      z-index: 99;
    }
    
    input {
      height: 3vmin;
      box-shadow: 0 0 0.1vh 0.1vw #193470;
      border: none;
      border-radius: 100%;
      padding: 1vh;
      background-color: #092153;
      outline: none;
      width: 3vmin;
      transition: 
        500ms width ease-in-out,
        500ms padding ease-in-out,
        500ms border-radius ease-in-out;
      }

    &-open svg {
      height: 3.4vh;
      padding: 0.2vw;
      position: absolute;
      cursor: pointer;
      fill: #fff;
    }
    
    &-open input {
      height: 1vh;
      padding: 1.5vh 1vw 1.5vh 2.5vw;
      background-color: #092153;
      font-family: inherit;
      font-size: 2vh;
      color: #fff;
      }

  }

  .hidden {
    clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
  }