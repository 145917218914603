.App {
  text-align: left;
  font-family: "Helvetica Neue", sans-serif;
  color: #fff;
}

.App-header {
  background-color: #071330;
  min-height: 5vh;
  display: grid;
  grid-template-columns: 40vw 60vw;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top: 0;
  z-index:1000;
}

.header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 3vw;
}

.App-logo {
  height: 10vmin;
  margin-left: 3vw;
  margin-right: 1.5vw;
}

.App-title {
  margin-left: 0;
}

.top-link {
  text-decoration: none !important;
  color: #fff;
}

.header-text {
  font-size: calc(5px + 2vmin);
  margin-right: 1.5vw;
}

@media only screen and (max-width: 900px) {
   .header-text {
      display: none;
  }
  .App-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .card{
    min-width: 21vw;
  }
  .episode-section{
    margin-left: 0.5vw;
    max-height: 22vmin;
  }
}

/* Search bar scss in ./components/searchBar.scss */

body {
  background-color: #141414;
}
  
.container {
  margin: 0 3vw;
}

.season-title {
  font-weight: normal;
  font-size: 3vmin;
}

.episode-section {
  display: flex;
  flex-direction: row;
  padding-left: 4vw;
  max-height: 25vmin;
}

.episode-section:focus-within .card,
.episode-section:focus, .card {
  transform: translate(-25%);
  z-index: 1;
}

.card:focus ~ .card,
.card:hover ~ .card {
  transform: translateX(25%);
}

.episode-section .card:focus,
.episode-section .card:hover {
  transform: scale(1.55);
  margin-left: 0.6vw;
  margin-right: 0.6vw;
  z-index: 1;
}

.card {
  width: 17.5vw;
  height: auto;
  border-radius: 5px;
  margin: 0.3vw;
  transition: transform 500ms;
  position: relative;
  display: block;
  background-color: #141414;
  font-size: 2vmin;
}

@media only screen and (max-width: 600px) {
  .card{
    min-width: 27vw;
    
  }
  .episode-section {
    margin-left: 2vw;
    max-height: 22vmin;
  }

  .App-title {
    font-size: 3.75vw;
  }
} 

@media only screen and (max-width: 400px) {
  .card{
    min-width: 37vw;
    margin-right: 1.5vw;
    
  }
  .episode-section {
    margin-left: 5.75vw;
    max-height: 30vmin;
  }

  .App-title {
    font-size: 3.75vw;
  }
} 

.meme-img {
  display: block;
  width: 100%;
  height: 80%;
  border-radius: 5px;
}

.alt-text {
  color: #141414;
  text-decoration: none !important;
}

.more-button {
  height: 10vh;
  align-self: center;
  position: relative;
  margin-left: -1vw;
  z-index: 99;
  margin-right: -1vw;
}

/*removed display: flex; from the more-button - in case of error*/

.more-button:hover {
  height: 13vh;
  padding: 2vh 0;
}

.down-button{
  height: 15vh;
  z-index: 99;
  transform: rotate(90deg);
}
.page-end {
  width: 100%;
  height: 30vh;
  background-color: #141414;
  display: flex;
  justify-content: center;
  padding-top: 5vh;
}

.footer {
  width: 100%;
  height: 20vh;
}

.footer-text {
  color: lightgray;
  text-align: center;
  font-size: 2vmin;
}